import { useMedia } from 'react-use'
import { Link, NavLink, useLocation } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import styled from 'styled-components'
import SvgIcon from '../svgIcon'
import { useEffect, useState } from 'react'

const Nav = styled.nav`
  @apply flex flex-col absolute top-[90px] left-0 bg-black bg-opacity-90 w-full h-[calc(100vh-90px)] sm:flex-row sm:relative sm:top-0 sm:w-auto sm:h-auto sm:bg-transparent;

  a {
    @apply w-full sm:flex-1 py-6 px-12 font-800;
  }

  &.hidden {
    @apply hidden;
  }
`

function Header() {
  const location = useLocation()
  const isMobile = useMedia('(max-width: 640px)')

  const [ show, setShow ] = useState(false)

  const handleToggle = (e) => {
    e.preventDefault()
    setShow(!show)
  }

  useEffect(() => {
    setShow(isMobile ? false : true)
  }, [isMobile])

  useEffect(() => {
    if(isMobile) setShow(false)
  }, [location])

  return (
    <header className={`fixed w-full py-5 bg-black text-[18px] ${show && isMobile ? 'bg-opacity-90' : 'bg-opacity-60'} z-10`}>
      <div className='flex justify-between items-center wrapper max-w-[1780px] text-white'>
        <Link to='/' className='shrink-0'>
          <img src={logo} style={{height: '50px'}} />
        </Link>
        {isMobile && (
          <a href='#' className='p-2' onClick={handleToggle}>
            <SvgIcon iconName='menu' className='w-6 h-6' />
          </a>
        )}
        <Nav className={`${!show ? 'hidden' : ''}`}>
          <NavLink to='/'>Home</NavLink>
          <NavLink to='/mint'>Purchase</NavLink>
          <NavLink to='/collections'>Collections</NavLink>
          <NavLink to={'/#landWrap'}>About</NavLink>
        </Nav>
      </div>
    </header>
  )
}

export default Header