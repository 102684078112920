import Header from './header.js'
import Footer from './footer.js'
import styled from 'styled-components'

const LayoutWrap = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
`

function Layout({ children }) {
  return (
    <LayoutWrap>
      <Header />
      { children }
      <Footer />
    </LayoutWrap>
  )
}

export default Layout