import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import SvgIcon from './components/svgIcon'
import Layout from './components/layout/index.js'
const Index = lazy(() => import('./pages/index'))
const Collections = lazy(() => import('./pages/collections'))
const Mint = lazy(() => import('./pages/mint'))
const Privacy = lazy(() => import('./pages/privacy'))
const TermsConditions = lazy(() => import('./pages/terms-conditions'))

const LoadingWrap = styled.div`
  @apply flex items-center justify-center w-screen h-screen;
`

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={(
        <LoadingWrap>
          <SvgIcon iconName='loading' className='w-6 h-6' />
        </LoadingWrap>
      )}>
        <Routes>
          <Route
            path='/'
            element={
              <Layout>
                <Index />
              </Layout>
            }
          />
          <Route
            path='/collections'
            element={
              <Layout>
                <Collections />
              </Layout>
            }
          />
          <Route
            path='/mint'
            element={
              <Layout>
                <Mint />
              </Layout>
            }
          />
          <Route
            path='/privacy'
            element={
              <Layout>
                <Privacy />
              </Layout>
            }
          />
          <Route
            path='/terms-conditions'
            element={
              <Layout>
                <TermsConditions />
              </Layout>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
