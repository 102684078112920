import PropTypes from 'prop-types'

const SvgIcon = props => {
  const { iconName, fill } = props
  return (
    <svg className={props.className} aria-hidden="true">
      <use xlinkHref={'#icon-' + iconName} fill={fill} />
    </svg>
  )
}

SvgIcon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  fill: PropTypes.string
}

SvgIcon.defaultProps = {
  fill: 'currentColor'
}

export default SvgIcon