import { Link } from 'react-router-dom'
function Footer() {
  return (
    <footer className="text-xl">
      <div className='flex flex-wrap justify-between items-center wrapper max-w-[1780px] pt-10 border-t-[1px] border-[#fff]'>
        <div className='w-full sm:w-auto pb-[42px] font-bold'>&copy; 2024 Virtual Valley. All right reserved.</div>
        <div className='flex flex-wrap w-full sm:w-auto font-extrabold'>
          <Link to="/terms-conditions" className="mr-[30px] pb-[42px]">
            <button data-type='primary' className='px-8'>Terms & Conditions</button>
          </Link>
          <Link to="/privacy" className='pb-[42px]'>
            <button data-type='primary' className='px-8'>Privacy Policy</button>
          </Link>
        </div>
      </div>
      
    </footer>
  )
}

export default Footer